import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import ContentHalfHero from "../../../../V2/Heroes/Content/HalfHero"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

const StoryblokContentHalfHero = ({
  blok,
}: Storyblok.BlokProps<Storyblok.ContentHalfHero>) => {
  return (
    <ContentHalfHero
      primaryBackgroundColor={blok.backgroundColor}
      secondaryBackgroundColor={blok.secondaryBackgroundColor}
      primaryButtonBorderColor={blok.primaryCTAColor}
      bodyText={blok.bodyText}
      headingText={blok.headingText}
      superscriptText={blok.superscriptText}
      imageAlt={blok.image.alt}
      imageUrl={blok.image.filename}
      primaryButtonLink={
        blok.primaryCTALink && linkIsNotNull(blok.primaryCTALink)
          ? getUrlFromStoryblokLink(blok.primaryCTALink)
          : undefined
      }
      primaryButtonMobileLink={
        blok.primaryCTAMobileLink && linkIsNotNull(blok.primaryCTAMobileLink)
          ? getUrlFromStoryblokLink(blok.primaryCTAMobileLink)
          : ""
      }
      primaryButtonText={blok.primaryCTAText}
      trackingEvent={blok.trackingEvent}
      trackingEventKey={blok.trackingEventKey}
      trackingEventValue={blok.trackingEventValue}
      secondaryButtonLink={getUrlFromStoryblokLink(blok.secondaryCTALink)}
      secondaryButtonText={blok.secondaryCTAText}
      secondaryCTATextColor={blok.secondaryCTATextColor}
      callout={blok.callout ? blok.callout[0] : undefined}
      showDownloadAppButton={blok.showDownloadAppButton}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokContentHalfHero
